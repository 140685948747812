"use client";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useEffect } from "react";
import { useI18n } from "../useI18n";
import { usePathname, useRouter } from "@/lib/i18n";
import { availableLanguageTags, languageTag } from "@/paraglide/runtime";

export const siteLanguageAtom = atomWithStorage<string>("siteLanguage", "en");

export const useSiteLanguage = () => {
  const [siteLanguage, setSiteLanguage] = useAtom(siteLanguageAtom);
  const { locale } = useI18n();

  useEffect(() => {
    if (siteLanguage) {
      return;
    }

    const detectLanguage = () => {
      // Try to get language from navigator
      if (typeof window !== "undefined" && window.navigator) {
        const browserLanguage =
          navigator.language ||
          (navigator as any).userLanguage ||
          (navigator as any).browserLanguage;

        if (browserLanguage) {
          // Extract the primary language code (e.g., 'en' from 'en-US')
          return browserLanguage.split("-")[0];
        }
      }

      // Fallback to default language if detection fails
      return "en";
    };

    setSiteLanguage(detectLanguage());
  }, [locale, setSiteLanguage, siteLanguage]);

  return {
    siteLanguage,
    setSiteLanguage,
  };
};

export const SiteLanguageRedirector = () => {
  const [siteLanguage, setSiteLanguage] = useAtom(siteLanguageAtom);
  const { locale } = useI18n();
  const currentPath = usePathname();
  const router = useRouter();

  useEffect(() => {
    if (!siteLanguage) return;
    const isHomePage = currentPath === "/" && locale === "en";
    if (isHomePage && siteLanguage !== "en") {
      if (availableLanguageTags.find((tag) => tag === siteLanguage)) {
        router.push(`${currentPath}`, {
          locale: siteLanguage as any,
        });
      }
    }
  }, [currentPath, locale, router, setSiteLanguage, siteLanguage]);

  return null;
};

/*
  if user is in home page without any language set
  -> check the language from atom and if it's the default language
  -> redirect to the that language
*/
