"use client";
/* eslint-disable @next/next/no-img-element */

import React, { useRef, useState, useCallback, useEffect } from "react";
import { useCombobox } from "downshift";
import axios, { CancelTokenSource } from "axios";
import { config } from "@/config";
import { Button } from "../ui/button";
import { Settings2, SettingsIcon } from "lucide-react";
import * as m from "@/paraglide/messages.js";

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAtom } from "jotai";
import { languageBoxAtom } from "../languageBox/LanguageBoxComp";
import { languagesAtoms } from "../atoms/languageAtom";
import { useI18n } from "../useI18n";
import { Poster } from "../shared/Poster";
import { languageTag } from "@/paraglide/runtime";
import { useRouter } from "@/lib/i18n";
import { recentSearchAtom } from "../atoms/recentSearchAtom";

export type AutoCompleteItem = {
  type: "movie" | "tv";
  name: string;
  poster_url: string;
  year: number;
  link: string;
  original_name: string;
};

function useDebounce<T extends (...args: any[]) => any>(
  callback: T,
  delay: number
) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return useCallback(
    (...args: Parameters<T>) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );
}

export const SearchInput = ({
  query,
  type,
}: {
  query?: string;
  type?: "movie" | "tv" | undefined;
}) => {
  const { isRTL, fontClassName } = useI18n();
  const [items, setItems] = useState<AutoCompleteItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(query || "");
  const requestRef = useRef<CancelTokenSource | null>(null);
  const router = useRouter();
  const [justTv, setJustTv] = useState(false);
  const [justMovie, setJustMovie] = useState(false);
  const [recentSearch, setRecentSearch] = useAtom(recentSearchAtom);

  const fetchMovies = useCallback(
    async (inputValue: string) => {
      try {
        if (requestRef.current) requestRef.current.cancel();

        requestRef.current = axios.CancelToken.source();
        if (!inputValue) {
          setItems([]);
          setLoading(false);
          return;
        }
        setLoading(true);
        const finalQuery = justTv
          ? `?query=t:${inputValue}`
          : justMovie
          ? `?query=m:${inputValue}`
          : `?query=${inputValue}`;

        const response = await axios.get(
          `${config.backend}/auto${finalQuery}`,
          {
            cancelToken: requestRef.current.token,
          }
        );
        setItems(response.data.results);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setItems([]);
        }
      } finally {
        setLoading(false);
      }
    },
    [justTv, justMovie]
  );

  const addToRecentSearch = (selectedItem: AutoCompleteItem) => {
    // not duplicate
    if (recentSearch.find((recent) => recent.name === selectedItem.name)) {
      return;
    }
    // save in atom if selected
    if (selectedItem) {
      // no more than 10 recent search
      setRecentSearch((prev) => {
        return [selectedItem, ...prev];
      });
    }
  };

  const clearRecentSearch = () => {
    setRecentSearch([]);
  };

  const debouncedFetchMovies = useDebounce(fetchMovies, 300);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
    selectedItem,
  } = useCombobox({
    defaultInputValue: query,
    items,
    onInputValueChange: ({ inputValue }) => {
      setInputValue(inputValue || "");
      if (inputValue) {
        setLoading(true);
        debouncedFetchMovies(inputValue);
      } else {
        setItems([]);
        setLoading(false);
      }
    },
    itemToString: (item) => (item ? item.name : ""),
    onSelectedItemChange: ({ selectedItem }) => {
      addToRecentSearch(selectedItem);

      if (selectedItem?.link) {
        router.push(selectedItem.link);
        return false;
      }
    },
  });

  const handleSeeAllResults = () => {
    router.push(`/search/${inputValue}`, {
      locale: languageTag(),
    });
  };
  const [focusedInput, setFocusedInput] = useState(false);

  const mode =
    loading === true || items.length > 0 || inputValue !== ""
      ? "search"
      : "recent";
  const finalItems = mode === "search" ? items : recentSearch.slice(0, 10);
  const finalDropdownOpen =
    (isOpen || focusedInput) &&
    !(mode === "recent" && recentSearch.length === 0);

  return (
    <div className={`w-full relative ${fontClassName}`}>
      <div className="flex shadow-sm gap-0.5">
        <input
          {...getInputProps({
            onKeyDown: (e) => {
              if (e.key === "Enter") {
                const locale = languageTag();
                router.push(`/search/${inputValue}`, {
                  locale: languageTag(),
                });
              }
            },
            onFocus: () => {
              setFocusedInput(true);
            },
            onBlur: () => {
              setFocusedInput(false);
            },
          })}
          autoComplete="off"
          placeholder={m.search_placeholder()}
          className="w-full rounded-md bg-white px-4 py-2 text-sm text-black dark:text-white placeholder:text-muted-black dark:placeholder:text-muted-white h-[46px] dark:bg-gray-800 outline-none"
        />
        {/* {loading && (
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2 z-10">
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900 dark:border-white"></div>
          </div>
        )} */}
        <div
          className={`absolute top-1/2 transform -translate-y-1/2 z-0 ${
            isRTL ? "left-1" : "right-1"
          }`}
        >
          {loading ? (
            <Button variant="link">
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900 dark:border-white" />
            </Button>
          ) : (
            <SearchSettings
              justTv={justTv}
              justMovie={justMovie}
              setTvSetting={setJustTv}
              setMovieSetting={setJustMovie}
            />
          )}
        </div>
      </div>
      <ul
        {...getMenuProps()}
        className={`absolute w-full bg-background mt-1 shadow-md max-h-80 overflow-auto p-0  py-1 ${
          !finalDropdownOpen && "hidden"
        } rounded-md border-2 ${fontClassName} ${isRTL ? "rtl" : "ltr"} `}
        style={{ zIndex: 150 }}
      >
        {mode === "recent" && (
          <li className="py-2 px-3 text-sm text-muted-foreground flex justify-between">
            {m.search_recent_search()}
            <div
              className="text-text3 text-sm cursor-pointer"
              onClick={clearRecentSearch}
            >
              {m.search_clear_recent_search()}
            </div>
          </li>
        )}

        {finalDropdownOpen && (
          <>
            {loading && (
              <li className="py-2 px-3 text-sm text-muted-foreground">
                Loading...
              </li>
            )}
            {!loading && finalItems.length === 0 && (
              <li className="py-2 px-3 text-sm text-muted-foreground">
                No results found
              </li>
            )}
            {finalItems.map((item, index) => (
              <li
                key={`${item.name}-${index}`}
                {...getItemProps({ item, index })}
                className={`py-1 px-2 shadow-sm flex items-center select-none cursor-pointer ${
                  highlightedIndex === index
                    ? "bg-accent text-accent-foreground"
                    : ""
                } ${selectedItem === item ? "font-bold" : ""} gap-2`}
              >
                {item.poster_url && (
                  // <img
                  //   src={item.poster_url}
                  //   alt={item.name}
                  //   className="w-10 h-18 object-cover mr-2 rounded-sm block"
                  // />
                  <Poster
                    src={item.poster_url}
                    title={item.name}
                    size="autoComplete"
                  />
                )}
                <div className="flex flex-col">
                  <span>{item.name}</span>
                  <span className="text-sm text-muted-foreground">
                    {item.type} • {item.year}
                  </span>
                </div>
              </li>
            ))}
            {!loading && finalItems.length === 0 && (
              <li
                className="py-2 px-3 text-sm text-primary hover:bg-accent hover:text-accent-foreground cursor-pointer"
                onClick={handleSeeAllResults}
              >
                {m.search_other_results({ query: inputValue })}
              </li>
            )}
          </>
        )}
      </ul>
    </div>
  );
};

const SearchSettings = ({
  justTv,
  justMovie,
  setTvSetting,
  setMovieSetting,
}: {
  justTv: boolean;
  justMovie: boolean;
  setTvSetting: React.Dispatch<React.SetStateAction<boolean>>;
  setMovieSetting: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { fontClassName, isRTL } = useI18n();
  const [isOpen, setIsOpen] = useAtom(languageBoxAtom);
  const [filteredLanguages] = useAtom(languagesAtoms);

  const toggleLangBox = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const selectedLanguageCount = filteredLanguages.length;

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="link">
            <Settings2 className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className={`w-56 ${fontClassName} `}
          style={{
            direction: isRTL ? "rtl" : "ltr",
          }}
        >
          <DropdownMenuLabel>{m.search_settings()}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuCheckboxItem
            checked={justTv}
            onCheckedChange={() => {
              const newState = !justTv;
              setTvSetting(newState);

              if (justMovie && newState) {
                setMovieSetting(false);
              }
            }}
          >
            {m.search_setting_tv()}
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={justMovie}
            onCheckedChange={() => {
              const newState = !justMovie;
              setMovieSetting(!justMovie);

              if (justTv && newState) {
                setTvSetting(false);
              }
            }}
          >
            {m.search_setting_movie()}
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={false}
            onCheckedChange={toggleLangBox}
          >
            {selectedLanguageCount === 0 ? (
              m.search_setting_selected()
            ) : (
              <div className="flex gap-1">
                {m.search_setting_has_selected_language()} (
                {selectedLanguageCount})
              </div>
            )}
          </DropdownMenuCheckboxItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
