import { useCallback, useEffect, useRef, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useAtom } from "jotai";
import dynamic from "next/dynamic";
import { languagesAtoms, languageSelectAtoms } from "../atoms/languageAtom";

const animatedComponents = makeAnimated();

export const LanguageBox = ({ closeClicked }: { closeClicked: () => void }) => {
  const [, setFilteredLanguages] = useAtom(languagesAtoms);
  const [selectedOptions, setSelectedOptions] = useAtom(languageSelectAtoms);
  const blankRef = useRef<HTMLDivElement | null>(null);

  const groupedOptions = [
    {
      label: "Popular Languages",
      options: popsLangs,
    },
    {
      label: "Other Languages",
      options: otherLangs,
    },
  ];

  useEffect(() => {
    const list = selectedOptions.map((item: any) => clearLangName(item.value));
    setFilteredLanguages(list);
  }, [selectedOptions, setFilteredLanguages]);

  const clickOutSide = useCallback(
    (event: any) => {
      if (event.target === blankRef.current) closeClicked();
    },
    [closeClicked]
  );

  useEffect(() => {
    if (typeof window === "undefined" || typeof document === "undefined") {
      return;
    }
    const keyDown = (event: any) => {
      if (event.keyCode == 27) {
        closeClicked();
      }
    };
    document.addEventListener("keydown", keyDown);
    return () => {
      document.removeEventListener("keydown", keyDown);
    };
  }, [blankRef, closeClicked]);

  return (
    <div
      onClick={clickOutSide}
      ref={blankRef}
      className="fixed inset-0 flex justify-center bg-gray-200 bg-opacity-40"
      style={{ zIndex: 200 }}
    >
      <div
        className="mt-[300px] w-[600px] h-[140px] p-5 relative bg-secondary rounded-lg shadow-md"
        style={{ zIndex: 200 }}
      >
        <div>
          <div className="flex justify-between items-center mb-4">
            <div className="text-base-content">
              please select your language
              <span className="ml-2 text-xs text-gray-400">[4 max]</span>
            </div>
            <button
              onClick={closeClicked}
              className="px-2 py-1 rounded bg-green-200 text-gray-700 cursor-pointer"
            >
              close
            </button>
          </div>
          <div>
            <Select
              id="long-value-select"
              key="test"
              instanceId="long-value-select"
              closeMenuOnSelect={true}
              value={selectedOptions}
              onChange={(o: any) => setSelectedOptions(o)}
              components={animatedComponents}
              isMulti
              isOptionDisabled={() => selectedOptions.length >= 4}
              options={groupedOptions}
              styles={{
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "#000",
                }),
                menu: (baseStyles, state) => ({
                  ...baseStyles,
                  zIndex: 999,
                }),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageBox;

export const clearLangName = (lang: string) => {
  const lang2 = lang.replace(/ /g, "");
  return lang2.toLowerCase().replace(/ /g, "_").replace(/\//g, "_");
};

export const popsLangs = [
  {
    value: "Arabic",
    label: "Arabic",
    color: "#59eca8",
  },
  {
    value: "Brazillian Portuguese",
    label: "Brazillian Portuguese",
    color: "#b576c7",
  },
  {
    value: "Danish",
    label: "Danish",
    color: "#9d32c2",
  },
  {
    value: "Dutch",
    label: "Dutch",
    color: "#8a3ed0",
  },
  {
    value: "English",
    label: "English",
    color: "#aef2d8",
  },
  {
    value: "Farsi/Persian",
    label: "Farsi/Persian",
    color: "#fcb5d0",
  },
  {
    value: "Finnish",
    label: "Finnish",
    color: "#0da826",
  },
  {
    value: "French",
    label: "French",
    color: "#5eb9a7",
  },
  {
    value: "Indonesian",
    label: "Indonesian",
    color: "#3a40ec",
  },
  {
    value: "Italian",
    label: "Italian",
    color: "#96b0b7",
  },
  {
    value: "Norwegian",
    label: "Norwegian",
    color: "#4f08cb",
  },
  {
    value: "Romanian",
    label: "Romanian",
    color: "#dd0679",
  },
  {
    value: "Spanish",
    label: "Spanish",
    color: "#bf0923",
  },
  {
    value: "Swedish",
    label: "Swedish",
    color: "#da4a22",
  },
  {
    value: "Vietnamese",
    label: "Vietnamese",
    color: "#6aba4d",
  },
];

export const otherLangs = [
  {
    value: "Albanian",
    label: "Albanian",
    color: "#ba9f87",
  },
  {
    value: "Azerbaijani",
    label: "Azerbaijani",
    color: "#fcde13",
  },
  {
    value: "South Azerbaijani",
    label: "South Azerbaijani",
    color: "#544c17",
  },
  {
    value: "Belarusian",
    label: "Belarusian",
    color: "#eaf118",
  },
  {
    value: "Bengali",
    label: "Bengali",
    color: "#f534cb",
  },
  {
    value: "Big 5 code",
    label: "Big 5 code",
    color: "#d34446",
  },
  {
    value: "Bosnian",
    label: "Bosnian",
    color: "#a1e2f8",
  },
  {
    value: "Bulgarian",
    label: "Bulgarian",
    color: "#c1759e",
  },
  {
    value: "Bulgarian/ English",
    label: "Bulgarian/ English",
    color: "#61dc17",
  },
  {
    value: "Burmese",
    label: "Burmese",
    color: "#cc7d08",
  },
  {
    value: "Catalan",
    label: "Catalan",
    color: "#dce638",
  },
  {
    value: "Chinese BG code",
    label: "Chinese BG code",
    color: "#1b2b0d",
  },
  {
    value: "Croatian",
    label: "Croatian",
    color: "#5bd0b8",
  },
  {
    value: "Czech",
    label: "Czech",
    color: "#c066ca",
  },
  {
    value: "Dutch/ English",
    label: "Dutch/ English",
    color: "#b634a1",
  },
  {
    value: "English/ German",
    label: "English/ German",
    color: "#49f110",
  },
  {
    value: "Esperanto",
    label: "Esperanto",
    color: "#5d1084",
  },
  {
    value: "Estonian",
    label: "Estonian",
    color: "#1aacd2",
  },
  {
    value: "Georgian",
    label: "Georgian",
    color: "#facdca",
  },
  {
    value: "German",
    label: "German",
    color: "#b16bb7",
  },
  {
    value: "Greek",
    label: "Greek",
    color: "#3a3446",
  },
  {
    value: "Greenlandic",
    label: "Greenlandic",
    color: "#9e1549",
  },
  {
    value: "Hebrew",
    label: "Hebrew",
    color: "#2d93e8",
  },
  {
    value: "Hindi",
    label: "Hindi",
    color: "#74e0fd",
  },
  {
    value: "Hungarian",
    label: "Hungarian",
    color: "#f5dcd8",
  },
  {
    value: "Hungarian/ English",
    label: "Hungarian/ English",
    color: "#bba103",
  },
  {
    value: "Icelandic",
    label: "Icelandic",
    color: "#29c294",
  },
  {
    value: "Japanese",
    label: "Japanese",
    color: "#5c0665",
  },
  {
    value: "Korean",
    label: "Korean",
    color: "#5253bb",
  },
  {
    value: "Kurdish",
    label: "Kurdish",
    color: "#f5dfd9",
  },
  {
    value: "Latvian",
    label: "Latvian",
    color: "#0a2a2d",
  },
  {
    value: "Lithuanian",
    label: "Lithuanian",
    color: "#03e1c7",
  },
  {
    value: "Macedonian",
    label: "Macedonian",
    color: "#4211d0",
  },
  {
    value: "Malay",
    label: "Malay",
    color: "#55909d",
  },
  {
    value: "Malayalam",
    label: "Malayalam",
    color: "#a92dda",
  },
  {
    value: "Manipuri",
    label: "Manipuri",
    color: "#2e0abf",
  },
  {
    value: "Polish",
    label: "Polish",
    color: "#9060b9",
  },
  {
    value: "Portuguese",
    label: "Portuguese",
    color: "#fe06f1",
  },
  {
    value: "Russian",
    label: "Russian",
    color: "#704d3b",
  },
  {
    value: "Serbian",
    label: "Serbian",
    color: "#e670e3",
  },
  {
    value: "Sinhala",
    label: "Sinhala",
    color: "#35f813",
  },
  {
    value: "Slovak",
    label: "Slovak",
    color: "#fde065",
  },
  {
    value: "Slovenian",
    label: "Slovenian",
    color: "#7bfe6e",
  },
  {
    value: "Tagalog",
    label: "Tagalog",
    color: "#1b0ccb",
  },
  {
    value: "Tamil",
    label: "Tamil",
    color: "#21f93a",
  },
  {
    value: "Telugu",
    label: "Telugu",
    color: "#788d96",
  },
  {
    value: "Thai",
    label: "Thai",
    color: "#47f4cd",
  },
  {
    value: "Turkish",
    label: "Turkish",
    color: "#abc36e",
  },
  {
    value: "Ukranian",
    label: "Ukranian",
    color: "#70c894",
  },
  {
    value: "Urdu",
    label: "Urdu",
    color: "#72ee8c",
  },
];

export const languageList = [
  ...popsLangs.map((lang) => clearLangName(lang.value.toLowerCase())),
  ...otherLangs.map((lang) => clearLangName(lang.value.toLowerCase())),
];
