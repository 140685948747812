import { atomWithStorage } from "jotai/utils";

export const languageSelectAtoms = atomWithStorage<string[]>(
  "language_select",
  []
);
export const languagesAtoms = atomWithStorage<string[]>(
  "filtered_languages",
  []
);

type option = {
  value: string;
  label: string;
  color: string;
};

export const languageRecentSubtitlesAtoms = atomWithStorage<option | undefined>(
  "language_recent_select",
  undefined
);
