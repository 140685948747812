"use client";

import { atom, useAtom } from "jotai";
import { languagesAtoms } from "../atoms/languageAtom";
import { useCallback } from "react";
import LanguageBox from "./LanguageBox";
export const languageBoxAtom = atom(false);

export const LanguageBoxComp = () => {
  const [filteredLanguages] = useAtom(languagesAtoms);
  const [isOpen, setIsOpen] = useAtom(languageBoxAtom);

  const toggleLangBox = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return <div>{isOpen && <LanguageBox closeClicked={toggleLangBox} />}</div>;
};
