// file generated by the Paraglide-Next init command
import { PrefixStrategy, Navigation, Middleware } from "@inlang/paraglide-next";
import type { AvailableLanguageTag } from "@/paraglide/runtime";

export const strategy = PrefixStrategy<AvailableLanguageTag>({
  prefixDefault: "never",
  prefixes: {
    en: "en",
    fa: "fa",
    ar: "ar",
    id: "id",
    fr: "fr",
    de: "de",
    es: "es",
    hi: "hi",
  },
});

export const middleware = Middleware({ strategy });
export const { Link, useRouter, usePathname, redirect, permanentRedirect } =
  Navigation({ strategy });
