"use client";
import { languageTag, onSetLanguageTag } from "@/paraglide/runtime";
import { useEffect, useState } from "react";

export const useI18n = () => {
  const [locale, setLocale] = useState(languageTag());

  // useEffect(() => {
  //   onSetLanguageTag((newLocale) => {
  //     setLocale(newLocale);
  //   });
  // }, []);

  onSetLanguageTag((newLocale) => {
    setLocale(newLocale);
  });

  const isRTL =
    locale.startsWith("ar") ||
    locale.startsWith("fa") ||
    locale.startsWith("ur") ||
    locale.startsWith("yi") ||
    locale.startsWith("ps") ||
    locale.startsWith("dv") ||
    locale.startsWith("sd") ||
    locale.startsWith("ug") ||
    locale.startsWith("ur") ||
    locale.startsWith("yi");

  const fontClassName = isRTL ? "font-rtlFont" : "";

  return {
    locale,
    isRTL,
    fontClassName,
  };
};
