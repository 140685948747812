"use client";
import { PlusCircle } from "lucide-react";
import Image from "next/image";
import { useState } from "react";

export const Poster = ({
  src,
  title,
  style,
  size,
  showShadow = false,
}: {
  src?: string;
  title: string;
  style?: React.CSSProperties;
  size?: "small" | "medium" | "large" | "autoComplete";
  showShadow?: boolean;
}) => {
  const [imgSrc, setImgSrc] = useState(src);
  const fallbackSrc = "https://subdl.com/images/poster.jpeg";

  return (
    <div
      className="select-none flex-shrink-0"
      style={{
        width: sizeCalc(size).width,
        height: sizeCalc(size).height,
        boxShadow: showShadow ? "0px 0px 10px rgba(0, 0, 0, 0.5)" : "none",
        overflow: "hidden",
        borderRadius: showShadow ? "5px" : "0px",
      }}
    >
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        alt={title}
        className="object-cover rounded-md"
        title={title}
        src={imgSrc || fallbackSrc}
        onError={() => setImgSrc(fallbackSrc)}
        style={{
          ...style,
          width: sizeCalc(size).width,
          height: sizeCalc(size).height,
        }}
      />
    </div>
  );
};

export const sizeCalc = (
  size?: "small" | "medium" | "large" | "autoComplete"
) => {
  switch (size) {
    case "small":
      return {
        width: 30,
        height: 45,
      };
    case "autoComplete":
      return {
        width: 46,
        height: 60,
      };
    case "medium":
      return {
        width: 90,
        height: 120,
      };
    case "large":
      return {
        width: 120,
        height: 180,
      };
    default:
      return {
        width: 60,
        height: 90,
      };
  }
};

export const EmptyPoster = ({
  style,
  size,
  showShadow = false,
  ...props
}: {
  style?: React.CSSProperties;
  size?: "small" | "medium" | "large" | "autoComplete";
  showShadow?: boolean;
}) => {
  return (
    <div
      className="select-none flex-shrink-0 rounded-md flex justify-center items-center"
      style={{
        width: sizeCalc(size).width,
        height: sizeCalc(size).height,
        boxShadow: showShadow ? "0px 0px 10px rgba(0, 0, 0, 0.5)" : "none",
        overflow: "hidden",
        borderRadius: showShadow ? "5px" : "0px",
        border: "1px solid #ccc",
      }}
      {...props}
    >
      <PlusCircle />
    </div>
  );
};
